var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"test_container",staticClass:"d-flex"},[_c('div',{staticClass:"test-container",class:{
      'test-container-on-scroll': _vm.window_top > 318 && _vm.evaluator_view,
    }},[_c('div',{staticClass:"container-buttons-instrument",class:{
        'lock-instrument-space-between':
          _vm.test.is_locked && _vm.user && !_vm.user.groups.includes(6),
      }},[_c('div',[(
            (_vm.allows_crud &&
              _vm.evaluation &&
              !_vm.test.is_locked &&
              !_vm.preview_evaluation) ||
            (_vm.allows_crud &&
              _vm.evaluation &&
              _vm.test.is_locked &&
              !_vm.preview_evaluation &&
              _vm.user &&
              _vm.user.groups.includes(6))
              ? _vm.allows_crud
              : false
          )?_c('b-button',{staticClass:"mt-3 ml-2",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(
              `modal-edit-instrument-${_vm.test.id}-${_vm.container_instrument_id}`
            )}}},[_c('b-icon-pencil-square',{staticClass:"pencil-icon"}),_vm._v(" Editar "+_vm._s(_vm.$getVisibleNames("evaluations2.test", false, "Instrumento"))+" ")],1):_vm._e(),(
            _vm.allows_crud &&
            !_vm.preview_evaluation &&
            _vm.evaluation &&
            !_vm.evaluation.test_creation_is_locked &&
            _vm.replicateEvaluationsList.length > 0 &&
            ((_vm.sectionsProfessor.length > 0 && _vm.evaluation.scope == 1) ||
              [2, 3].includes(_vm.evaluation.scope))
          )?_c('b-button',{staticClass:"mt-3 ml-2",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.openReplicateTest()}}},[_c('b-icon',{attrs:{"icon":"files"}}),_vm._v(" Replicar "+_vm._s(_vm.$getVisibleNames("evaluations2.test", false, "Instrumento"))+" ")],1):_vm._e()],1),_c('div',{staticClass:"mt-3"},[(
            !_vm.test.is_locked &&
            _vm.evaluation &&
            _vm.allows_crud &&
            _vm.user &&
            !_vm.user.groups.includes(6) &&
            !_vm.preview_evaluation
          )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
            `Bloquear  ${_vm.$getVisibleNames(
              'evaluations2.test',
              false,
              'Instrumento'
            )}`
          ),expression:"\n            `Bloquear  ${$getVisibleNames(\n              'evaluations2.test',\n              false,\n              'Instrumento'\n            )}`\n          ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"lock-btn p-0 mr-2 noprint",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.changeStateTest(_vm.test)}}},[_c('b-icon-unlock-fill')],1):_vm._e(),(
            _vm.test.is_locked &&
            _vm.evaluation &&
            _vm.allows_crud &&
            _vm.user &&
            !_vm.user.groups.includes(6) &&
            !_vm.preview_evaluation
          )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
            `Desbloquear  ${_vm.$getVisibleNames(
              'evaluations2.test',
              false,
              'Instrumento'
            )}`
          ),expression:"\n            `Desbloquear  ${$getVisibleNames(\n              'evaluations2.test',\n              false,\n              'Instrumento'\n            )}`\n          ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"lock-btn p-0 mr-2 noprint",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.changeStateTest(_vm.test)}}},[_c('b-icon-lock-fill')],1):_vm._e()],1)]),_c('b-modal',{attrs:{"id":`modal-edit-instrument-${_vm.test.id}-${_vm.container_instrument_id}`,"title":`Editar ${_vm.$getVisibleNames(
        'evaluations2.test',
        false,
        'Instrumento'
      )}`,"hide-footer":"","size":"xl"}},[_c('NewInstrumentForm',{attrs:{"evaluation_id":_vm.evaluation_id,"Instrument":_vm.test,"show_step":_vm.evaluation &&
          (_vm.evaluation.matter_evaluation != null ||
            _vm.evaluation.egress_profile_matter_evaluation != null),"locked_instrument":_vm.test.is_locked,"show_delete_button":!_vm.existEvaluateeTest,"ClosedEvaluatee":_vm.existEvaluateeTest},on:{"closeModal":function($event){return _vm.$bvModal.hide(
            `modal-edit-instrument-${_vm.test.id}-${_vm.container_instrument_id}`
          )}}})],1),_c('b-modal',{attrs:{"id":`modal-copy-instrument-${_vm.test.id}-${_vm.container_instrument_id}`,"title":`Replicar ${_vm.$getVisibleNames(
        'evaluations2.test',
        false,
        'Instrumento'
      )} en otras evaluaciones.`,"hide-footer":"","size":"lg"},on:{"hide":() => {
          _vm.selected_evaluations_ids = [];
        }}},[_c('b-form-group',{staticClass:"m-0 p-0 mb-2",attrs:{"label":`Seleccione las evaluaciones a donde se van a replicar:`,"label-for":"selected_evaluations_ids","label-cols":"0","label-cols-sm":"auto","label-cols-md":"auto"}},[_c('v-select',{staticClass:"mt-1",attrs:{"id":"selected_evaluations_ids","placeholder":`Seleccione uno o varios para filtrar.`,"track-by":"id","label":"full_name","size":"sm","options":_vm.replicateEvaluationsList,"reduce":(replicateEvaluationsList) => replicateEvaluationsList.id,"disabled":!_vm.allows_crud,"dropdown-should-open":_vm.dropdownShouldOpen,"multiple":"","selectable":(option) => !_vm.selected_evaluations_ids.includes(option.id)},scopedSlots:_vm._u([{key:"no-options",fn:function({ search, searching }){return [(searching)?[_vm._v(" No se encontró resultados para "),_c('em',[_vm._v(_vm._s(search))]),_vm._v(". ")]:_vm._e()]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.full_name)+" ")]}}]),model:{value:(_vm.selected_evaluations_ids),callback:function ($$v) {_vm.selected_evaluations_ids=$$v},expression:"selected_evaluations_ids"}})],1),_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"ml-auto mr-0",attrs:{"size":"sm","disabled":_vm.selected_evaluations_ids.length == 0},on:{"click":_vm.replicateTests}},[_vm._v(" Aplicar ")])],1)],1),(_vm.evaluatee_view)?_c('EvaluateeTime',{attrs:{"is_published":_vm.isPublishedTest,"test":_vm.test,"evaluatee_view":_vm.evaluatee_view,"can_answer":_vm.canAnswer(),"is_running_test":_vm.is_running_test,"finished_test":_vm.isFinishedTest}}):_vm._e(),_c('HeaderInstrument',{class:{
        'd-none':
          _vm.isformatPartialTest ||
          _vm.isformatTransversalInformation ||
          _vm.isformatTransversalExam ||
          _vm.isformatCorrectAnswerPartial ||
          _vm.isformatCorrectAnswerTransversal,
      },attrs:{"instrument_id":_vm.test.id,"allows_crud":_vm.allows_crud && !_vm.test.is_locked && _vm.evaluation && !_vm.preview_evaluation
          ? _vm.allows_crud
          : false,"user_id":_vm.userId,"finished_test":_vm.isFinishedTest,"container_instrument_id":_vm.container_instrument_id,"evaluatee_view":_vm.evaluatee_view,"evaluator_view":_vm.evaluator_view,"preview_evaluation":_vm.preview_evaluation,"isPrintingRubrica":_vm.isPrintingRubrica,"isPrintingInstrumento":_vm.isPrintingInstrumento},on:{"change_preview_evaluation":_vm.previewEvaluation}}),(
        _vm.evaluatee_view && _vm.canAnswer() && !_vm.is_running_test && !_vm.isFinishedTest
      )?[_c('h4',[_vm._v(" La evaluación comenzará el \""),_c('strong',[_vm._v(_vm._s(_vm._f("FormatToDateTime")(_vm.$moment(_vm.test.start_time))))]),_vm._v("\" "),_c('div',{staticClass:"time-label-start start-time-label-start"},[_vm._v("Dentro de:")]),_c('div',{staticClass:"time-card-start"},[_c('Timer',{attrs:{"end_date":_vm.test.start_time}})],1)])]:_vm._e(),(_vm.evaluatee_view && !_vm.canAnswer())?_c('div',[_c('h4',[_vm._v("Usted no tiene permiso para contestar")])]):_vm._e(),(
        _vm.evaluatee_view && !_vm.evaluateeTest && _vm.is_running_test && _vm.canAnswer()
      )?_c('b-button',{staticClass:"mt-4 mb-4",attrs:{"variant":"primary"},on:{"click":_vm.startInstrument}},[_vm._v(" Iniciar "+_vm._s(_vm.$getVisibleNames("evaluations2.test", false, "Instrumento"))+" ")]):_vm._e(),(
        !_vm.evaluatee_view ||
        (_vm.evaluatee_view && _vm.canAnswer() && _vm.is_running_test && _vm.evaluateeTest) ||
        (_vm.evaluatee_view && _vm.canAnswer() && _vm.isFinishedTest) ||
        _vm.evaluator_view
      )?[_c('EvaluativeSituation',{class:{
          'print-hide':
            _vm.isformatTransversalExam ||
            _vm.isformatCorrectAnswerPartial ||
            _vm.isformatCorrectAnswerTransversal,
        },attrs:{"instrument_id":_vm.test.id,"evaluation_situations":_vm.evaluation_situations,"matter_evaluation":_vm.matter_evaluation}}),_c('EvaluativeAgent',{class:{
          'print-hide':
            _vm.isPrintingInstrumento ||
            _vm.isformatTransversalInformation ||
            _vm.isformatTransversalExam ||
            _vm.isformatCorrectAnswerPartial ||
            _vm.isformatCorrectAnswerTransversal,
          'print-show': _vm.isPrintingRubrica,
        },attrs:{"instrument_id":_vm.test.id,"matter_evaluation":_vm.matter_evaluation}}),(
          _vm.evaluation &&
          (_vm.evaluation.matter_evaluation != null ||
            _vm.evaluation.egress_profile_matter_evaluation != null ||
            _vm.evaluation.parent_evaluation) &&
          ((_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
            _vm.$debug_change_duoc)
        )?_c('DuocSpecificationTable',{class:{
          'print-hide':
            _vm.isPrintingInstrumento ||
            (_vm.checked_transversal_specification_table &&
              _vm.isformatTransversalExam) ||
            _vm.isformatCorrectAnswerPartial ||
            _vm.isformatCorrectAnswerTransversal,
          'print-show': _vm.isPrintingRubrica,
        },attrs:{"container_instrument_id":_vm.container_instrument_id,"allows_crud":_vm.allows_crud && _vm.evaluation && !_vm.preview_evaluation
            ? _vm.allows_crud
            : false,"preview_evaluation":_vm.preview_evaluation,"instrument_id":_vm.test.id,"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids}}):_vm._e(),_c('TestDescriptorsDraggable',{class:{
          'print-hide':
            _vm.isPrintingInstrumento || _vm.isformatCorrectAnswerTransversal,
          'print-show': _vm.isPrintingRubrica,
        },attrs:{"preview_evaluation":_vm.preview_evaluation,"container_instrument_id":_vm.container_instrument_id,"test_id":_vm.test.id,"allows_crud":_vm.allows_crud && _vm.evaluation && !_vm.preview_evaluation
            ? _vm.allows_crud
            : false,"position_number":1}}),_c('CorrectAnswerComponent',{staticClass:"d-none",class:{
          'print-hide':
            _vm.isPrintingInstrumento ||
            _vm.isformatPartialTest ||
            _vm.isformatTransversalInformation ||
            _vm.isformatTransversalExam,
          'print-show':
            _vm.isformatCorrectAnswerPartial || _vm.isformatCorrectAnswerTransversal,
        },attrs:{"questions_list":_vm.questionList,"evaluatee_view":_vm.evaluatee_view}}),(
          !_vm.allows_crud &&
          !_vm.evaluatee_view &&
          !_vm.evaluator_view &&
          _vm.institution &&
          _vm.institution.internal_use_id != 'duoc_uc'
        )?_c('div',{staticClass:"container-btn-print mb-2 mr-2 noprint"},[_c('b-dropdown',{attrs:{"split":"","variant":"primary","size":"sm"},on:{"click":_vm.printInstrument},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"printer"}}),_c('span',[_vm._v(" Imprimir "+_vm._s(_vm.$getVisibleNames("evaluations2.test", false, "Instrumento"))+" ")])],1)]},proxy:true}],null,false,818525695)},[_c('b-dropdown-form',{staticStyle:{"width":"285px"}},[_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"name":"check-score","switch":""},model:{value:(_vm.checked_score),callback:function ($$v) {_vm.checked_score=$$v},expression:"checked_score"}},[_vm._v(" Ocultar "),_c('b',[_vm._v("Puntaje")]),_vm._v(" al Imprimir ")]),_c('b-dropdown-divider'),_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"name":"check-weightning","switch":""},model:{value:(_vm.checked_weightning),callback:function ($$v) {_vm.checked_weightning=$$v},expression:"checked_weightning"}},[_vm._v(" Ocultar "),_c('b',[_vm._v("Ponderación")]),_vm._v(" al Imprimir ")])],1)],1)],1):_vm._e(),(
          !_vm.evaluatee_view &&
          !_vm.evaluator_view &&
          !_vm.preview_evaluation &&
          ((_vm.evaluationSituation &&
            _vm.evaluationSituation.enable_questions &&
            (_vm.evaluationSituation.enable_redaction_questions ||
              _vm.evaluationSituation.enable_simple_selection_questions ||
              _vm.evaluationSituation.enable_multiple_selection_questions ||
              _vm.evaluationSituation.enable_matching_questions)) ||
            _vm.questionList.length > 0)
        )?_c('div',{staticClass:"d-flex justify-content-left mb-2 mr-2 noprint"},[_c('b-button',{staticClass:"noprint",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(
              `modal-specification-table-test-${_vm.test.id}-${_vm.container_instrument_id}`
            )}}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"card-list"}}),_c('span',[_vm._v("Tabla de Especificaciones")])],1)]),_c('b-modal',{attrs:{"id":`modal-specification-table-test-${_vm.test.id}-${_vm.container_instrument_id}`,"title":`Tabla de Especificaciones`,"hide-footer":"","size":"xl"}},[_c('NewSpecificationTable',{attrs:{"allows_crud":_vm.allows_crud,"test":_vm.test,"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids,"evaluation_situations":_vm.evaluation_situations}})],1)],1):_vm._e(),(
          _vm.test.allow_group_creation && _vm.evaluation && _vm.evaluation.scope == 1
        )?_c('GroupsContainer',{attrs:{"Test":_vm.test,"allows_crud":_vm.allows_crud,"container_instrument_id":_vm.container_instrument_id,"evaluatee_view":_vm.evaluatee_view,"evaluator_view":_vm.evaluator_view,"preview_evaluation":_vm.preview_evaluation,"user_id":_vm.userId}}):_vm._e(),(!(_vm.evaluatee_view && !_vm.isPublishedTest && _vm.isFinishedTest))?_c('BodyInstrument',{key:'BodyInstrument-' + _vm.key_evaluation_criteria,class:{
          'print-hide':
            _vm.isPrintingRubrica ||
            _vm.isformatTransversalInformation ||
            _vm.isformatCorrectAnswerPartial ||
            _vm.isformatCorrectAnswerTransversal,
          'print-show': _vm.isPrintingInstrumento,
        },attrs:{"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids,"container_instrument_id":_vm.container_instrument_id,"instrument_id":_vm.test.id,"allows_crud":_vm.allows_crud && _vm.evaluation && !_vm.preview_evaluation
            ? _vm.allows_crud
            : false,"is_published":_vm.isPublishedTest,"preview_evaluation":_vm.preview_evaluation,"checked_score":_vm.checked_score,"checked_weightning":_vm.checked_weightning,"evaluatee_view":_vm.evaluatee_view,"evaluator_view":_vm.evaluator_view,"is_running_test":_vm.is_running_test,"finished_test":_vm.isFinishedTest,"user_id":_vm.userId,"closedEvaluationScore":_vm.closedEvaluationScore,"requests_within_the_component":_vm.requests_within_the_component,"evaluation_situations":_vm.evaluation_situations},on:{"is_fetch_completed":function($event){return _vm.$emit('is_fetch_completed')}}}):_vm._e(),(
          !(_vm.evaluatee_view && !_vm.isPublishedTest && _vm.isFinishedTest) &&
          _vm.questionList.length > 0
        )?_c('TestDescriptorsDraggable',{class:{
          'print-hide':
            _vm.isPrintingInstrumento || _vm.isformatCorrectAnswerTransversal,
          'print-show': _vm.isPrintingRubrica,
        },attrs:{"preview_evaluation":_vm.preview_evaluation,"container_instrument_id":_vm.container_instrument_id,"test_id":_vm.test.id,"allows_crud":_vm.allows_crud && _vm.evaluation && !_vm.preview_evaluation
            ? _vm.allows_crud
            : false,"position_number":2}}):_vm._e(),(
          !_vm.allows_crud &&
          !_vm.evaluatee_view &&
          !_vm.evaluator_view &&
          _vm.institution &&
          _vm.institution.internal_use_id != 'duoc_uc'
        )?_c('div',{staticClass:"container-btn-print mb-2 mr-2 noprint"},[_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.printRubric}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"printer"}}),_c('span',[_vm._v("Imprimir "+_vm._s(_vm.$getVisibleNames("evaluations2.rubric", false, "Pauta"))+" ")])],1)])],1):_vm._e(),_c('RubricsContainer',{key:'RubricsContainer-' + _vm.key_evaluation_criteria,staticClass:"mb-4",class:{
          'print-hide':
            _vm.isPrintingInstrumento ||
            (_vm.checked_rubric_partial && _vm.isformatPartialTest) ||
            _vm.isformatTransversalInformation ||
            (_vm.checked_rubric_transversal && _vm.isformatTransversalExam) ||
            _vm.isformatCorrectAnswerTransversal,
          'print-show': _vm.isPrintingRubrica,
        },attrs:{"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids,"container_instrument_id":_vm.container_instrument_id,"instrument_id":_vm.test.id,"allows_crud":_vm.allows_crud && _vm.evaluation && !_vm.preview_evaluation
            ? _vm.allows_crud
            : false,"preview_evaluation":_vm.preview_evaluation,"evaluator_view":_vm.evaluator_view,"evaluatee_view":_vm.evaluatee_view,"is_running_test":_vm.is_running_test,"finished_test":_vm.isFinishedTest,"is_published":_vm.isPublishedTest,"user_id":_vm.userId,"closedEvaluationScore":_vm.closedEvaluationScore,"requests_within_the_component":_vm.requests_within_the_component}}),(
          _vm.evaluatee_view &&
          _vm.test.student_can_upload_attachments &&
          _vm.evaluateeTest
        )?_c('div',{staticClass:"mb-4"},[(_vm.evaluateeTest.student_attachments.length > 0)?[_c('div',{staticClass:"d-flex justify-content-center"},[_c('FileContainer',{attrs:{"Files":_vm.evaluateeTest.student_attachments,"allows_crud":!_vm.isFinishedTest},on:{"deleteFile":_vm.slotDeleteStudentFile}})],1)]:_vm._e(),(!_vm.isFinishedTest)?_c('b-button',{staticClass:"mt-1",attrs:{"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(
              `modal-select-file-student-${_vm.test.id}-${_vm.container_instrument_id}`
            )}}},[_vm._v("Subir Archivo")]):_vm._e(),_c('b-modal',{attrs:{"id":`modal-select-file-student-${_vm.test.id}-${_vm.container_instrument_id}`,"title":"Seleccionar Archivos","hide-footer":""}},[_c('FileSelector',{on:{"created":_vm.slotCreatedStudentFile}})],1)],2):_vm._e(),(
          _vm.evaluatee_view &&
          _vm.canAnswer() &&
          _vm.is_running_test &&
          _vm.evaluateeTest &&
          !_vm.evaluateeTest.is_finished
        )?_c('b-button',{staticClass:"mb-4 mt-3",attrs:{"variant":"primary"},on:{"click":_vm.closeInstrument}},[_vm._v(" Finalizar "+_vm._s(_vm.$getVisibleNames("evaluations2.test", false, "Instrumento"))+" ")]):_vm._e()]:_vm._e()],2),(_vm.evaluator_view)?[_c('div',{staticClass:"noprint question-test-score",class:{
        'sticky-container': _vm.window_top > 318 && _vm.evaluator_view,
      }},[_c('EvaluatorTestScore',{attrs:{"test":_vm.test,"user_id":_vm.userId,"closedEvaluationScore":_vm.closedEvaluationScore},on:{"previous_tabs":function($event){return _vm.$emit('previous_tabs')},"next_tabs":function($event){return _vm.$emit('next_tabs')}}})],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }